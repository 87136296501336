.wrapper {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  background-color: #ffffff;
  border: 3px solid transparent;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 var(--border-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  min-width: 160px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: normal;
  position: relative;
  /*pointer-events: none;*/
  opacity: 0.9;
  font-size: 16px;
}

.large {
  min-width: 170px;
}

.wrapper.isMobile {
  min-width: auto;
}

.wrapper p {
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  white-space: nowrap;
}

.wrapper h4 {
  color: var(--color-grey-dark);
  font-size: 14px;
  font-weight: normal;
}

.wrapper:hover {
  background-color: rgba(255, 255, 255, 0.75);
}

.wrapper.selected {
  border-color: var(--color-main);
  /*background-color: var(--color-main);*/
  color: var(--color-main);
  font-weight: bold;
  pointer-events: initial;
  opacity: 1;
}

.wrapper.fulfilled {
  pointer-events: initial;
  opacity: 0.6;
}

.wrapper.hasNudge .statusIcon {
  width: 12px;
  height: 12px;
  top: -8px;
  left: -8px;
  background-color: var(--color-rose);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  display: block;
  border-radius: 100px;
}

.wrapper.fulfilled .statusIcon {
  top: -12px;
  left: -12px;
  width: 24px;
  height: 24px;
  box-shadow: none;
  border-radius: 0;
  display: block;
}

.statusIcon {
  position: absolute;
  z-index: 5;
  display: none;
}

.wrapper h4.selected {
  color: var(--color-main);
}

@media screen and (max-width: 699px) {
  .wrapper {
    padding: 5px 16px;
    font-size: 14px;
  }

  .statusIcon {
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
  }
}
