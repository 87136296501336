.container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
}

.option {
  margin: 56px 0;
}

@media screen and (max-width: 699px) {
  .container {
    width: 100%;
    margin: 0;
    padding: 0 0 48px 0;
    overflow: hidden;
  }
}
