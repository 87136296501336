.container {
  text-align: center;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-height: 800px) {
  .container {
    height: 80px !important;
    padding: 8px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 24px;
  }
}

@media screen and (max-width: 699px) {
  .container {
    padding: 8px;
    height: 72px !important;
  }
}
