.container,
.dots {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dots {
  margin: 16px 0;
}

.button {
  opacity: 0.75;
}

.button[disabled] {
  color: grey;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: var(--color-main);
  border-radius: 100px;
  opacity: 0.35;
  margin: 4px 0;
  cursor: pointer;
  transition: 0.1s transform;
}

.dot:hover {
  opacity: 0.25;
  transform: scale(1.2);
}

.dot.selected {
  opacity: 1;
}

.dot.nudge {
  background-color: var(--color-rose);
}

.dot.disabled {
  background-color: var(--color-grey-light);
}

@media screen and (max-width: 699px) {
  .button {
  }
}
