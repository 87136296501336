.container {
  display: flex;
  align-items: center;
}

.container p {
  flex-grow: 1;
  margin-right: 8px;
  font-size: 0.75em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 16px;
  background-color: var(--color-grey-background);
  border-radius: var(--border-radius);
}
