:root {
  --color-main: #62a8ac;
  --color-grey-light: #d8d8d8;
  --color-grey-lighter: #ebebeb;
  --color-grey-dark: #444446;
  --color-grey-darker: #444446;
  --color-grey-background: #f3f4f5;
  --color-disabled-button: #c7c7cc;
  --color-border: #e4e4e4;
  --color-green: #074F57;
  --color-yellow: #f7b500;
  --color-orange: #f5a623;
  --color-rose: #EA526F;
  --color-red: #EA526F;

  --border-radius: 6px;
  --border-color: #dee1e6;
}
* {
  box-sizing: border-box;
  margin: 0;
}

html,
body {
  overflow: hidden;
}

body {
  font-size: 18px;
  font-family: "Work Sans", sans-serif;
  color: var(--color-grey-darker);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-grey-background);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h6 {
  font-size: 0.77em;
}

h5 {
  color: var(--color-grey-light);
  font-size: 0.777em;
}

h4 {
  font-size: 1.33em;
}

h3 {
  color: var(--color-grey-dark);
  font-weight: 500;
  font-size: 1.111em;
}

h2 {
  font-size: 2.22em;
}

h1 {
  font-size: 2.66em;
}

p {
  font-size: inherit;
  letter-spacing: 0.3px;
  line-height: 1.4;
}

label {
  font-size: 0.88em;
  color: #666666;
}

.white {
  color: white;
}

input,
select {
  text-align: left;
  padding: 14px 16px;
  background-color: white;
  border-radius: 5px;
  color: #666666;
  border: 2px solid #c8c8c8;
  font-size: 16px;
  font-weight: 500;
  font-family: inherit;
  outline: none;
  height: 48px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
  margin: 24px 0;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.align-bottom {
  align-items: baseline;
}

.center-vert {
  align-items: center;
}

.center-hori {
  justify-content: center;
}

.full-width {
  width: 100%;
}

a {
  text-decoration: none;
  color: var(--color-main);
}

[hidden] {
  display: none !important;
}

.show-on-tablet {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .hide-on-tablet {
    display: none !important;
  }

  .show-on-tablet {
    display: initial !important;
  }
}

@media screen and (max-width: 699px) {
  body {
    font-size: 16px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .hide-on-mobile {
    display: none !important;
  }
}
