.items {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 0 24px;
  justify-content: center;
}

@media screen and (max-width: 699px) {
  .items {
    overflow-x: auto;
    margin: 0;
    padding: 0 8px;
    align-items: baseline;
  }

  .wrap {
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: 100vw;
  }

  .wrap > *:first-child {
    padding-left: 24px;
  }

  .wrap > *:last-child {
    padding-right: 60px;
  }
}
