.wrapper {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container {
  width: 120px;
  padding-top: 120px;
  background-repeat: no-repeat;
  background-size: 110px 110px;
  background-position: center;
  background-color: #ffffff;
  border: 3px solid transparent;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 var(--border-color);
  border-radius: 6px;
  cursor: pointer;
}

.container:hover {
  background-color: rgba(200, 200, 200, 0.05);
}

.container.selected {
  border-color: var(--color-main);
}

.wrapper h4.selected {
  color: var(--color-main);
}

.heading {
  padding: 16px;
  margin-bottom: 16px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.helperIcon {
  margin-left: 8px;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.1s all;
}

.helperIcon:hover {
  opacity: 0.95;
}

.label {
  font-size: 14px;
  margin-top: 6px;
  color: var(--color-grey-darker);
}

.label.error {
  color: var(--color-red);
}

/*.label.error::before {
  content: "";
  background-size: 12px 12px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+U2hhcGU8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGcgaWQ9IkZsb3ciIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkRlc2t0b3BfRGVzaWduZXItQ29weS00IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTE1LjAwMDAwMCwgLTM5OC4wMDAwMDApIiBmaWxsPSIjRjE0QjQ0IiBmaWxsLXJ1bGU9Im5vbnplcm8iPiAgICAgICAgICAgIDxnIGlkPSJpbmZvLTI0cHgtKDIpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5MTUuMDAwMDAwLCAzNjMuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik01LjQsMzggTDYuNiwzOCBMNi42LDM5LjIgTDUuNCwzOS4yIEw1LjQsMzggWiBNNS40LDQwLjQgTDYuNiw0MC40IEw2LjYsNDQgTDUuNCw0NCBMNS40LDQwLjQgWiBNNiwzNSBDMi42ODgsMzUgMCwzNy42ODggMCw0MSBDMCw0NC4zMTIgMi42ODgsNDcgNiw0NyBDOS4zMTIsNDcgMTIsNDQuMzEyIDEyLDQxIEMxMiwzNy42ODggOS4zMTIsMzUgNiwzNSBaIE02LDQ1LjggQzMuMzU0LDQ1LjggMS4yLDQzLjY0NiAxLjIsNDEgQzEuMiwzOC4zNTQgMy4zNTQsMzYuMiA2LDM2LjIgQzguNjQ2LDM2LjIgMTAuOCwzOC4zNTQgMTAuOCw0MSBDMTAuOCw0My42NDYgOC42NDYsNDUuOCA2LDQ1LjggWiIgaWQ9IlNoYXBlIj48L3BhdGg+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+);
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  padding-bottom: 2px;
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
}*/

@media screen and (max-width: 699px) {
  .wrapper {
    margin: 0;
  }

  .heading {
    margin-bottom: 8px;
  }
}
