.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-height: 800px) {
  .heading {
    display: none !important;
  }
}

@media screen and (max-width: 699px) {
  .button {
    height: 40px;
    width: 160px;
  }
}
