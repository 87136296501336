.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  height: 100%;
  display: inherit;
  flex-direction: inherit;
  padding: 16px 0 16px 0;
  z-index: 2;
}

.steps {
  margin-top: -64px;
  z-index: 2;
}

@media screen and (max-width: 1024px) {
  .content {
    padding: 0;
  }
}

@media screen and (max-height: 800px) {
  .content {
    padding: 0;
  }
}
