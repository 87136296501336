.wrapper {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 8px 16px;
  flex-direction: column;
  max-width: 120px;
}

.wrapper.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.wrapper.hidden {
  display: none;
}

.wrapper h4 {
  color: var(--color-grey-darker);
  font-size: 14px;
  margin-top: 8px;
}

.wrapper h4.selected {
  color: var(--color-main);
}

.label {
  font-size: 14px;
  opacity: 1;
  white-space: pre-line;
  margin-top: 6px;
}

.error {
  color: var(--color-red);
}

@media screen and (max-width: 699px) {
  .wrapper {
    margin: 8px 0;
    width: 105px;
  }

  h4 {
    font-size: 12px;
  }

  .label {
    font-size: 12px;
    margin-top: 2px;
  }
  .container {
    width: 80px;
    padding-top: 80px;
    background-size: 75px 75px;
  }
}
