.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 440px;
  padding: 24px;
  position: absolute;
  min-height: 100%;
}

.items {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.yarnSpecs {
  margin-top: 16px;
}

.presets {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.presets > div {
  margin: 8px 16px;
  width: 70px;
  height: 40px;
  display: flex;
  font-size: 0.88em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #ffffff;
  border: 2px solid transparent;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 var(--border-color);
  border-radius: var(--border-radius);
}

.presets > div:hover {
  background-color: rgba(200, 200, 200, 0.06);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15), 0 0 1px 0 var(--border-color);
}

.presets > .selected {
  border-color: var(--color-main);
}

.description {
  font-size: 0.88em;
  margin-top: 8px;
}

.items > div {
  margin: 8px 0;
}

.inputSection {
  margin: 16px 0;
  width: 100%;
  text-align: center;
}

.inputGroup {
  display: flex;
  justify-content: space-between;
}

.inputGroup > div {
  width: calc(50% - 8px);
}

@media screen and (max-width: 699px) {
  .form {
    padding: 24px;
    max-width: 100%;
  }

  .items {
    margin-top: 8px;
  }

  .items > div {
    margin: 12px 0;
  }
}
