.sponsoredLabel {
  font-size: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 36px);
}

.sponsoredLink {
  position: relative;
  margin-bottom: 16px;
  display: block;
}
