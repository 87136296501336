.content {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden;
}

.left {
  width: 50%;
  max-width: 700px;
  margin-left: auto;
  position: relative;
  min-height: 240px;
  padding-top: 16px;
  background: white;
  /*transform: translateX(40px);*/
  border-right: 1px solid var(--color-border);
}

.right {
  width: 50%;
  overflow-y: auto;
  padding-top: 16px;
}

@media screen and (max-width: 699px) {
  .content {
    flex-direction: column;
    overflow-y: auto;
  }

  .left,
  .right {
    width: 100%;
  }

  .left {
    padding: 40px 0;
    margin-top: 56px;
    min-height: 340px;
    border-right: none;
    border-bottom: 1px solid var(--color-border);
  }

  .right {
    overflow: initial;
    padding-top: 0;
  }
}
