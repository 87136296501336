.image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /*position: relative;*/
}

.image > img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 32px;
}

.image.noPadding > img {
  padding: 0;
}

@media screen and (max-width: 699px) {
  .image {
    position: initial;
  }

  .image > img {
    padding: 16px;
  }

  .image.noPadding > img {
    padding: 0;
  }
}
