.container {
  min-width: 120px;
  padding-top: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #ffffff;
  border: 3px solid transparent;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 var(--border-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all 0.2s;
}

.container:hover {
  background-color: rgba(200, 200, 200, 0.06);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15), 0 0 1px 0 var(--border-color);
}

.container.selected {
  border-color: var(--color-main);
}

.large {
  min-width: 120px;
  padding-top: 120px;
}

.medium {
  min-width: 96px;
  padding-top: 96px;
}

.medium {
  min-width: 80px;
  padding-top: 80px;
}

.disabled {
  pointer-events: none;
}

@media screen and (max-height: 900px) {
  .container {
    min-width: 80px !important;
    padding-top: 80px !important;
    background-size: 75px 75px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    min-width: 80px !important;
    padding-top: 80px !important;
    background-size: 75px 75px;
  }

  .container.small {
  }
}
