.container {
  text-align: center;
  height: 88px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.container h1 {
  font-weight: 300;
}
