.container {
  width: 100%;
  padding: 40px 0;
  margin: 0 auto;
  max-width: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.heading {
  text-align: center;
}

.heading > h4 {
  margin-bottom: 4px;
}

.container > * {
  margin-bottom: 24px !important;
}

@media screen and (max-width: 699px) {
  .container {
    padding: 24px;
  }
}
