.container {
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 32px auto 16px auto;
  display: flex;
  flex-grow: 1;
  max-width: 1280px;
  max-height: 800px;
  overflow: hidden;
}

.container > div {
  width: 50%;
}

.preview {
  border-right: 1px solid var(--border-color);
  position: relative;
  overflow: hidden;
  /*background-color: #f9f9f9;*/
  background-color: white;
}

.settings {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  /*background-color: #f9f9f9;*/
}

.settings.center-vert {
  align-items: center;
}

@media screen and (max-height: 800px) {
  .container {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
    max-height: 1200px;
    margin: 0;
  }

  .container > div {
    width: 100%;
  }

  .preview {
    padding: 24px;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
    background-color: white;
    min-height: 32vh;
  }

  .settings {
    background-color: rgba(220, 220, 220, 0.09);
    flex-grow: 1;
  }
}
