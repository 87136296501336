.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  position: initial;
  top: 0;
  overflow: hidden;
  overscroll-behavior-y: none;
}

.content {
  position: relative;
  transition: margin 200ms;
  transition-delay: 50ms;
}

.item {
  padding: 16px 0;
  transition: 0.2s all;
  cursor: pointer;
}

.previousArea,
.nextArea {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25%;
  max-height: 200px;
  opacity: 0;
  transition: 0.15s;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previousArea:hover,
.nextArea:hover {
  background-color: rgba(200, 200, 200, 0.05);
  opacity: 1;
}

.nextArea {
  left: 0;
  bottom: 0;
  top: unset;
}

.controls {
  position: fixed;
  bottom: 0;
  right: 0;
}

.opaque {
  opacity: 0.35;
  /*transform: scale(0.95);*/
  filter: blur(2px);
}

.opaque > * {
  pointer-events: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.dots {
  position: absolute;
  right: 24px;
  top: 0;
  height: 100%;
  margin: 0 !important;
}

@media screen and (max-width: 699px) {
  .dots {
    right: 12px;
  }

  .previousArea,
  .nextArea {
    display: none;
  }
}
