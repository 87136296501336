.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 8px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
}
