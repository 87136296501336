.container {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
}

.items {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 24px 16px 0 16px;
}

.item {
  text-align: center;
  margin: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
