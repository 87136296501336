.buttons {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.buttons > *:first-of-type {
  margin-right: 8px !important;
}

.fixedWidth {
  max-width: 480px;
  width: 100%;
  justify-content: space-between;
}

.fixedWidth > * {
  width: calc(50% - 8px);
  height: 48px;
  margin: 0 !important;
}

@media screen and (max-width: 699px) {
  .fixedWidth > * {
    width: calc(50% - 4px);
    height: 40px;
  }
}
