.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 300ms;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.container.show {
  opacity: 1;
  pointer-events: initial;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinner p {
  margin-top: 12px;
}
