.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 360px;
}

.first {
  display: flex;
  position: relative;
}

.first img {
  width: 96px;
  height: 96px;
  border-radius: 6px;
  object-fit: cover;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 var(--border-color);
}

.itemImage {
  width: 96px;
  padding-top: 96px;
  background-repeat: no-repeat;
  background-size: 110px 110px;
  background-position: center;
  background-color: #ffffff;
  border: 3px solid transparent;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 var(--border-color);
  border-radius: 6px;
  cursor: pointer;
}

.itemContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 12px;
  margin-right: 4px;
}

.itemContent p {
  margin-top: 6px;
  font-size: 16px;
}
