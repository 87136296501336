.header {
  width: 100%;
  background: transparent;
  /*border-bottom: 1px solid #e8e8e8;*/
  /*box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);*/
  position: fixed;
  z-index: 3;
  top: 0;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 32px 32px 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right {
  display: flex;
  align-items: center;
  position: fixed;
  right: 32px;
  top: 32px;
}

.right > *:not(:last-child) {
  margin-right: 8px;
}

.logo {
  height: 56px;
  cursor: pointer;
  position: fixed;
  left: 32px;
  top: 32px;
}

.emojiSelect {
  filter: grayscale(1);
  opacity: 0.5;
  color: rgba(0, 0, 0, 0.8) !important;
}

.emojiSelect p {
  width: 24px;
  height: 24px;
  line-height: 1;
}

.emojiSelect:hover {
  filter: grayscale(0);
  opacity: 1;
}

@media screen and (max-height: 800px) {
  .logo {
    left: 16px;
    top: 16px;
  }

  .right {
    right: 20px;
    top: 20px;
  }
}

@media screen and (max-width: 699px) {
  .header {
    z-index: 8;
  }

  .wrapper {
    padding: 16px 8px 0 16px;
  }

  .wrapper.showBackground {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 16px 8px 16px 16px;
  }

  .saveForLater {
    display: none !important;
  }

  .logo {
    height: 40px;
    position: relative;
    left: 0;
    top: 0;
  }

  .right {
    position: relative;
    right: 0;
    top: 0;
  }
}
