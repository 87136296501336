.wrapper {
  text-align: center;
  padding: 16px 0 24px 0;
  opacity: 0.9;
  white-space: pre-line;
}

.icon {
  font-size: 64px;
}

.wrapper p {
  margin: 12px 0 24px 0;
  line-height: 1.5;
}
