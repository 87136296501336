.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 24px;
  background-color: #f9f9f9;
  min-height: 64px;
  width: 100%;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.content {
  padding: 24px 24px;
  overflow-y: auto;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  border-top: 1px #f6f6f6 solid;
}

.actions > * {
  margin-left: 8px;
}

@media screen and (max-width: 699px) {
  .header {
    font-size: 16px;
    padding-left: 16px;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
  }

  .content {
    padding: 16px;
  }
}
