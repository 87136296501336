.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.steps {
  display: flex;
  margin: 0 auto;
  overflow: auto;
  /*padding: 8px;*/
  position: relative;
}

.steps > * {
  margin: 0 4px;
}

.steps > *:first-of-type {
  margin-left: 40px;
}

.steps > *:last-of-type {
  margin-right: 40px;
}

.container > * {
  margin: 0 8px;
}

.fab {
  min-width: 40px !important;
  position: absolute;
  left: 0;
  bottom: 0;
}

@media screen and (max-width: 1024px) {
  .container {
  }
}

@media screen and (max-width: 699px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .steps {
    justify-content: flex-start;
  }
}
